.contact {
    display: flex;
    justify-content: center;
    align-items: center;
    /* min-height: 100vh; */
    /* background: #826afb; */
}

form {
    max-width: 600px;
    width: 100%;
    background: #fff;
    padding: 0 25px;
    /* border-radius: 8px; */
    /* box-shadow: 0 0 10px rgba(0, 0, 0, .1); */
    color: #333;
    /* margin: 25px; */
}

form h2 {
    font-size: 30px;
    text-align: center;
}

form .input-box {
    margin-top: 20px;
}

.input-box .field {
    width: 100%;
    height: 20px;
    background: transparent;
    border: 2px solid #a6a083;
    outline: none;
    border-radius: none;
    padding: 15px;
    font-size: 16px;
    color: #333;
    margin-top: 8px;
}

.input-box .field.mess {
    height: 200px;
    resize: none;
}

form button {
    width: 100%;
    /* height: 75px; */
    /* background: #826afb; */
    /* background-image: url(../../images/navTile_up.png) ;
    background-repeat: repeat-x;
    background-position: top left;
    background-size: contain; */
    /* border: 2px solid #000; */
    /* border-radius: 15px; */
    /* box-shadow: 0 0 10px rgba(0, 0, 0, .1); */
    background-color: #fff;
    border: none;
    cursor: pointer;
    font-size: 1.5em;
    color: #000;
    font-weight: bold;
    margin-top: 25px;
    transition: .5s;
    /* text-transform: uppercase; */

}

form button:hover {
    /* background: #624ec6; */
}

label {
    text-align: left !important;
    display: block !important;
    font-weight: bold;
}