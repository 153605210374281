// EXPERIENCE ANIMATION
/* EXPERIENCE KEYFRAMES */
@-webkit-keyframes animateExperience {
    0% {
        margin-left: -1500px;
    }

    100% {
        margin-left: 100%;
    }
}

@-moz-keyframes animateExperience {
    0% {
        margin-left: -1500px;
    }

    100% {
        margin-left: 100%;
    }
}

@keyframes animateExperience {
    0% {
        margin-left: -1500px;
    }

    100% {
        margin-left: 100%;
    }
}

@-webkit-keyframes animateExperience {
    0% {
        margin-left: -1500px;
    }

    100% {
        margin-left: 100%;
    }
}

@-moz-keyframes animateExperience {
    0% {
        margin-left: -1500px;
    }

    100% {
        margin-left: 100%;
    }
}

@keyframes animateExperience {
    0% {
        margin-left: -1500px;
    }

    100% {
        margin-left: 100%;
    }
}

@-webkit-keyframes animateExperience2 {
    0% {
        margin-left: 100%;
    }

    100% {
        margin-left: -2000px;
    }
}

@-moz-keyframes animateExperience2 {
    0% {
        margin-left: 100%;
    }

    100% {
        margin-left: -2000px;
    }
}

@keyframes animateExperience2 {
    0% {
        margin-left: 100%;
    }

    100% {
        margin-left: -2000px;
    }
}

@-webkit-keyframes animateExperience2 {
    0% {
        margin-left: 100%;
    }

    100% {
        margin-left: -2000px;
    }
}

@-moz-keyframes animateExperience2 {
    0% {
        margin-left: 100%;
    }

    100% {
        margin-left: -2000px;
    }
}

@keyframes animateExperience2 {
    0% {
        margin-left: 100%;
    }

    100% {
        margin-left: -2000px;
    }
}

/* EXPERIENCE ANIMATIONS */
@media only screen and (min-width: 953px) {
    .mid-Z-Sub-tile.experience-wrap {
        height: 215px !important;
        overflow: hidden;
        font-family: "Bruno Ace SC", sans-serif;

        #background-wrapXXX {
            height: 215px !important;
            overflow: hidden;

            .x1 {
                -webkit-animation: animateExperience 15s linear infinite;
                -moz-animation: animateExperience 15s linear infinite;
                animation: animateExperience 15s linear infinite;
                -webkit-transform: scale(0.60);
                -moz-transform: scale(0.60);
                transform: scale(0.60);
                position: relative;
                top: 0;

                .experience {
                    background-image: url(../images/experience/animated-interface-icon.png);
                    background-repeat: no-repeat;
                    background-size: contain;
                    background-position: top center;
                    height: 131px;
                    width: 180px;
                    opacity: .5;
                }
            }

            .x2 {
                -webkit-animation: animateExperience2 16s linear infinite;
                -moz-animation: animateExperience2 16s linear infinite;
                animation: animateExperience2 16s linear infinite;
                -webkit-transform: scale(0.3);
                -moz-transform: scale(0.3);
                transform: scale(0.3);
                position: relative;
                top: -15px;

                .experience {
                    background-image: url(../images/experience/animated-phone-icon.png);
                    background-repeat: no-repeat;
                    background-size: contain;
                    background-position: top center;
                    height: 141px;
                    width: 94px;
                    opacity: .5;
                }
            }

            .x3 {
                -webkit-animation: animateExperience2 9s linear infinite;
                -moz-animation: animateExperience2 9s linear infinite;
                animation: animateExperience2 9s linear infinite;
                -webkit-transform: scale(0.6);
                -moz-transform: scale(0.6);
                transform: scale(0.6);
                position: relative;
                top: -190px;

                .experience {
                    background-image: url(../images/experience/animated-landscape-icon.png);
                    background-repeat: no-repeat;
                    background-position: top left;
                    background-size: contain;
                    height: 257px;
                    width: 197px;
                    opacity: .5;
                }
            }

            .x4 {
                -webkit-animation: animateExperience 7s linear infinite;
                -moz-animation: animateExperience 7s linear infinite;
                animation: animateExperience 7s linear infinite;
                -webkit-transform: scale(0.3);
                -moz-transform: scale(0.3);
                transform: scale(0.3);
                position: relative;
                top: -600px;

                .experience {
                    background-image: url(../images/experience/animated-portrait-icon.png);
                    background-repeat: no-repeat;
                    background-position: top left;
                    background-size: contain;
                    height: 257px;
                    width: 197px;
                    opacity: .5;
                }
            }

            .x5 {
                -webkit-animation: animateExperience2 15s linear infinite;
                -moz-animation: animateExperience2 15s linear infinite;
                animation: animateExperience2 15s linear infinite;
                -webkit-transform: scale(1);
                -moz-transform: scale(1);
                transform: scale(1);
                position: relative;
                top: -650px;
               
                .experience {
                    // background-image: url(../images/experience/animated-portrait-icon.png);
                    // background-repeat: no-repeat;
                    // background-position: top left;
                    // background-size: contain;
                    
                    color: #fff;
                    font-size: 1.8em;
                    height: auto;
                    width: 700px;
                    opacity: .4;
                }
            }

            .x6 {
                -webkit-animation: animateExperience 17s linear infinite;
                -moz-animation: animateExperience 17s linear infinite;
                animation: animateExperience 17s linear infinite;
                -webkit-transform: scale(1);
                -moz-transform: scale(1);
                transform: scale(1);
                position: relative;
                top: -700px;

                .experience {
                    // background-image: url(../images/experience/animated-portrait-icon.png);
                    // background-repeat: no-repeat;
                    // background-position: top left;
                    // background-size: contain;
                    content: 'GRAPHICS';
                    height: auto;
                    width: 700px;
                    font-size: 1.5em;
                    color: #fff;
                    opacity: .5;
                }
            }

            .x7 {
                -webkit-animation: animateExperience 12s linear infinite;
                -moz-animation: animateExperience 12s linear infinite;
                animation: animateExperience 12s linear infinite;
                -webkit-transform: scale(1);
                -moz-transform: scale(1);
                transform: scale(1);
                position: relative;
                top: -830px;

                .experience {
                    // background-image: url(../images/experience/animated-portrait-icon.png);
                    // background-repeat: no-repeat;
                    // background-position: top left;
                    // background-size: contain;
                    height: auto;
                    width: 500px;
                    font-size: 1.5em;
                    color: #fff;
                    opacity: .5;
                }
            }

            .x8 {
                -webkit-animation: animateExperience 21s linear infinite;
                -moz-animation: animateExperience 21s linear infinite;
                animation: animateExperience 21s linear infinite;
                -webkit-transform: scale(1);
                -moz-transform: scale(1);
                transform: scale(1);
                position: relative;
                top: -830px;

                .experience {
                    // background-image: url(../images/experience/animated-portrait-icon.png);
                    // background-repeat: no-repeat;
                    // background-position: top left;
                    // background-size: contain;
                    height: auto;
                    width: 500px;
                    font-size: 1.5em;
                    color: #fff;
                    opacity: .5;
                }
            }

            .x9 {
                -webkit-animation: animateExperience2 19s linear infinite;
                -moz-animation: animateExperience2 19s linear infinite;
                animation: animateExperience2 19s linear infinite;
                -webkit-transform: scale(1);
                -moz-transform: scale(1);
                transform: scale(1);
                position: relative;
                top: -770px;

                .experience {
                    // background-image: url(../images/experience/animated-portrait-icon.png);
                    // background-repeat: no-repeat;
                    // background-position: top left;
                    // background-size: contain;
                    height: auto;
                    width: 500px;
                    font-size: 1.5em;
                    color: #fff;
                    opacity: .5;
                }
            }
        }
    }
}