.z-container {
    position: fixed;
    display: flex;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    visibility: hidden;
    opacity: 0;
    transition: opacity 1s, visibility 1s;
    z-index: 9999999999999999999;

    .z-modal {
        text-align: center;
        height: 90%;
        max-width: 60%;
        margin: 0 auto;
        position: relative;
        opacity: 1;
        z-index: 99999999999999999999999999999999999;
        margin-top: 20px;

        .z-modal-closer {
            display: block;
            text-align: right;

            .close {
                cursor: pointer;
                float: none;
                opacity: 1;
            }
        }

        .z-modal-header,
        .z-modal-footer {
		    display:flex;

            img {
                flex-shrink: 0;
                width: 100%;
                min-width:100%;
                min-height:100%
            }
        }

        .z-modal-body {
            background-color: #fff;
            background-image: url(./images/modal-tile.png);
            background-repeat: repeat-y;
            background-position: top left;
            background-size: contain;
            padding: 15px;
            font-weight: bold;

            a {
               color: #a5251e;
            }

            @media only screen and (max-width: 767px) {
                padding: 5px 10px;
            }

            p {
                width: 90%;
                padding: 0 15px;
                margin: 0 auto;
            }

            .text-center.scroll-message {
                margin-top: 20px;
                font-size: .8em;
            }

            .modalScroll {
              
                    /* overflow: -moz-scrollbars-vertical; 
                    overflow-y: scroll;
                 */
                width: 90%;
                max-height: 60vh;
                margin: 10px auto;
                overflow-y: scroll;
                scrollbar-color:  #aa9d69 #335c56;
                scrollbar-width: thick;
                border: 1px solid #335c56;

                @media only screen and (max-width: 767px) {
                    max-height: 30vh;
                }
            }
        }

        @media only screen and (max-width: 952px) {
            max-width: 95%;
        }  
    }

    /* img {
        
    } */

    p {
        color: #000;
    }
}

.z-container.active {
    background-color: rgba(0,0,0,.8);
    visibility: visible;
    opacity: 1;
    /* z-index: 9999999999999999999999999999999999 !important;*/
    z-index: 9999999999999999999; 
}

.toggleModal {
    display: block;
    background-color: red;
    cursor: pointer;
}