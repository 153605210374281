@charset "UTF-8";
.bruno-ace-sc-regular {
	font-family: "Bruno Ace SC", sans-serif;
	font-weight: 400;
	font-style: normal;
  }
  

body {
	// background-image: url(../images/body_X_Tile2.png);
	background-image: url(../images/body_sub_X_Tile.png);
	background-position: top left;
	background-repeat: repeat-x;
	background-color: #000 !important;
	margin: 0px;
	font-family: Arial, Helvetica, sans-serif;
	color: #FFFFFF;
	background-color: #000;
	overflow-x: hidden;
	scrollbar-color: #020d0b #aa9d69;
	scrollbar-width: thin;
}


.top-Z-tile {
	width: 100%;
	z-index: -1;
	position: absolute;
	top: 0px;
	left: 0px;
	height: 108px;
	background: url(../images/top-tile-alpha-Z-1.png) top left repeat-x;
}

.mid-Z-tile {
	background: url(../images/home-tile-mid-Z-botLayer.jpg);
	background-position: top left;
	background-repeat: repeat-x;
	width: 100%;
	z-index: -2;
	position: absolute;
	top: 87px;
	left: 0px;
	height: 422px;

	@media only screen and (max-width: 952px) {
		
	}
}
/* 
.sub.interface-slides .mid-Z-tile {
	
	
} */

.bot-Z-tile {
	width: 100%;
	z-index: -1;
	position: absolute;
	top: 423px;
	left: 0px;
	height: 300px;
	background: url(../images/bot-tile-alpha-Z-1.png) top left repeat-x;
}

.top-Z-Proj-tile {
	width: 100%;
	z-index: -1;
	position: absolute;
	top: 0px;
	left: 0px;
	height: 108px;
	background: url(../images/top-tile-alpha-Z-1.png) top left repeat-x;
}

.mid-Z-Proj-tile {
	width: 100%;
	z-index: -2;
	position: absolute;
	top: 87px;
	left: 0px;
	height: 422px;
	background: url(../images/slide-tile-mid-Z-botLayer.jpg) top left repeat-x;
}

.mid-Z-Proj-tilePrint {
	z-index: -2;
	position: absolute;
	width: 100%;
	top: 87px;
	left: 0;
	height: 422px; 
	background-image: url(../images/bg-bells-whistles/interstellar.jpg);
	background-repeat: no-repeat;
	background-position: top center;
	background-size: cover;

		@media only screen and (min-width: 2020px) {
			/* background-position: top -120px center, top left; */
			/* background-size: 100% cover, auto; */
		}
	
		@media only screen and (min-width: 1800px) and (max-width: 2019px) {
			/* background-position: top -50px center, top left; */
			/* background-size: cover, auto; */
		}
	
		@media only screen and (min-width: 1400px) and (max-width: 1799px) {
			/* background-position: top 20px center, top left; */
			/* background-size: contain, auto; */
		}
	
		@media only screen and (min-width: 953px) and (max-width: 1399px) {
			/* background-position: top center, top left; */
			/* background-size: contain, auto; */
		}


	@media only screen and (max-width: 952px) {
		/* background: url(../images/home-tile-mid-Z-botLayer.jpg); */
	}
}

.mid-Z-Proj-tileWeb {
	width: 100%;
	z-index: -2;
	position: absolute;
	top: 87px;
	left: 0;
	height: 422px; 
	/* src/images/bg-bells-whistles/leftTechno.png */
	background-image: url(../images/bg-bells-whistles/longTechno.png),
	url(../images/home-tile-mid-Z-botLayer.jpg);
	background-repeat: no-repeat, repeat-x;
	background-size: cover, auto;
	background-position: top -50px center, top left;

	@media only screen and (min-width: 2020px) {
		/* background-position: top -120px center, top left; */
		/* background-position: top center, top left; */
		/* background-size: 100% cover, auto; */
		/* background-size: 100% contain, auto; */
	}

	@media only screen and (min-width: 1800px) and (max-width: 2019px) {
		/* background-position: top -50px center, top left; */
		/* background-position: top center, top left; */
		/* background-size: cover, auto; */
	}

	@media only screen and (min-width: 1400px) and (max-width: 1799px) {
		/* background-position: top 20px center, top left; */
		/* background-size: 100% contain, auto; */
	}

	@media only screen and (min-width: 953px) and (max-width: 1399px) {
		/* background-position: top center, top left; */
		/* background-size: 100% contain, auto; */
	}

	/*
	@media only screen and (min-width: 953px) {
		/* background-position: top -20px center, top left;
		background-size: 120%, auto; 
	} */

	@media only screen and (max-width: 952px) {
		background: url(../images/home-tile-mid-Z-botLayer.jpg);
	}
}

.bot-Z-Proj-tile {
	width: 100%;
	z-index: -1;
	position: absolute;
	top: 504px;
	left: 0px;
	height: 300px;
	background: url(../images/bot-tile-alpha-Z-1.png) top left repeat-x;
}

.top-Z-Sub-tile {
	width: 100%;
	z-index: -1;
	position: absolute;
	top: 0px;
	left: 0px;
	height: 108px;
	background: url(../images/top-tile-alpha-Z-1.png) top left repeat-x;
}

.mid-Z-Sub-tile {
	width: 100%;
	z-index: -2;
	position: absolute;
	top: 89px;
	left: 0px;
	height: 215px;
	background: url(../images/subPage-tile-mid-Z-botLayer.png) top left repeat-x;
	// border: 10px red solid;
}

.bot-Z-Sub-tile {
	width: 100%;
	z-index: -1;
	position: absolute;
	top: 303px;
	left: 0px;
	height: 300px;
	background: url(../images/bot-tile-alpha-Z-1.png) top left repeat-x;
}

#city2 {
	position: absolute;
	border: 0px red solid;
	width: 100%;
	-moz-opacity: .50;
	filter: alpha(opacity=50);
	opacity: .50;
	height: 516px;
	background: transparent url(../images/radial-lithe-tres.png) 0 80px repeat-x;
}

#city1 {
	-moz-opacity: .50;
	filter: alpha(opacity=50);
	opacity: .40;
	position: absolute;
	top: 100px;
	border: 0px red solid;
	top: 60px;
	width: 100%;
	height: 416px;
	background: transparent url(../images/radial-lithe.png) 0 130px repeat-x;
}

// body.sub {
// 	background-image: url(../images/body_sub_X_Tile.png);
// 	background-position: top left;
// 	background-repeat: repeat-x;
// 	margin: 0px;
// 	font-family: Arial, Helvetica, sans-serif;
// 	color: #FFFFFF;
// 	background-color: #000;
// }

// body.sub a:hover {
// 	text-decoration: underline;
// }

body.proj {
	background-image: url(../images/body_X_ProjSlideTile.png);
	background-position: top left;
	background-repeat: repeat-x;
	margin: 0px;
	font-family: Arial, Helvetica, sans-serif;
	color: #FFFFFF;
	background-color: #000000;
}

img {
	border: 0px;
}

#mainWrapper {
	width: 953px;
	position: relative;
	margin: 0px auto;
	border: 0px solid #FFFFFF;

	@media only screen and (max-width: 952px) {
		width: 100%;
	}
}

.footer {
	background-color: #000;
	text-align: center;
	width: 100%;
}

.footer a:link {
	color: #fff;
}

.footer a:hoverk {
	color: #fff;
}

.footer a:visited {
	color: #fff;
}

.da-container {
	overflow-x: hidden;
	margin: 0 auto;
}

.header {
	max-width: 953px;
	width: 100%;
	height: 162px;
	margin: 0 auto;
	position: relative;
	z-index: 2;

	@media only screen and (max-width: 952px) {
		width: 100%;
	}

	@media only screen and (max-width: 952px) {
		width: 100%;
	}
}

.headerLeft {
	background-image: url(../images/headAngleLeft.png);
	width: 121px;
	height: 162px;
	background-position: top left;
	margin: 0px;
	float: left;

	@media only screen and (max-width: 952px) {
		display: none;
		width: 0;
	}
}

.headerMain {
	background-image: url(../images/headTile.png);
	background-repeat: repeat-x;
	background-position: top left;
	width: 711px;
	height: 125px;
	float: left;

	@media only screen and (max-width: 952px) {
		width: 100%;
	}
}

.headerLogo {
	text-align: center;
}

#utility {
	color: #4a4a4a;
	font-size: 11px;
	position: absolute;
	top: -5px;
	left: 600px;
	height: 32px;
	width: 200px;
	font-size: 10px;

	@media only screen and (max-width: 952px) {
		width: 100%;
		position: fixed;
		display: none;
	}
}

#utility td {
	vertical-align: middle;
	padding-bottom: 20px;
}

#U_left {
	width: 24px;
	height: 29px;
}

#U_mid {
	background-image: url(../images/utility_tile.png);
	text-align: center;
	width: 200px;
	height: 29px;
	background-position: top left;
	background-repeat: repeat-x;
}

#U_right {
	width: 24px;
	height: 29px;
}

#utility table {
	width: 220px;
}

#utility a:link {
	color: #4a4a4a;
	text-decoration: none;
	font-weight: bold;
}

#utility a:hover {
	color: #4a4a4a;
	font-weight: bold;
	text-decoration: underline;
}

#utility a:visited {
	color: #4a4a4a;
	text-decoration: none;
	font-weight: bold;
}

#headerNav {
	border: 0px #000 solid;
	margin: 0px 0px 0px 0px;
	float: right;
	padding: 0px;
	width: 711px;
	position: relative;

	@media only screen and (max-width: 952px) {
		width: 100%;
		background: url(../images/navTile_up.png) repeat-x top left;
	}

	@media only screen and (max-width: 767px) {
		height: 50px;
		padding-top: 5px;
		background-position: bottom left;
		background-size: contain;

		.navbar-nav {
			background-color: transparent;
		}
	}
}

#headerNav a:link {
	color: #000000;
}

#headerNav ul {
	border: 0 #000 solid;
	min-height: 37px;
	padding: 0px;
	width: 711px;
	list-style-type: none;
	text-align: center;

	@media (min-width: 768px) {
		min-height: 37px;
	}

	@media only screen and (max-width: 767px) {
		background: #fff;
	}
}

#headerNav ul li {
	font-size: 14px;
	display: inline;
	float: left;
	font-weight: bold;

	@media only screen and (max-width: 952px) {
		background-image: url(../images/navTile_up.png);
		background-repeat: repeat-x;
		background-position: top left;
		display: block;
		float: none;
		/* margin-right: 15%; */
		width: 180px;
	}

	@media only screen and (max-width: 767px) {
		background-image: url(../images/mobile-menu/images/mobile-slideout-tile.png);
		background-size: 100%;
		background-repeat: repeat-y;
		background-position: left top;
		margin-right: 0;
		width: 90%;
	}
}

@media only screen and (max-width: 767px) {
	#headerNav ul li.bottom-mob-nav-logo {
		background-image: none;
		background-color: transparent;
		position:relative;
		display:flex;
		justify-content:center;
		align-items:center;
		overflow: hidden;
		width: 100%;
		/* background-image: url(../images/mobile-menu/images/mobile-slideout-logo.png);
		background-size: cover;
		min-height: 300px;
		background-position: bottom; */

		.mob-slide-logo {
			flex-shrink:0;
			width: 100%;
			min-width:100%;
			min-height:100%
		}
	}
}

@media only screen and (min-width: 768px) {
	.bottom-mob-nav-logo {
		position:relative;
		display:flex;
		justify-content:center;
		align-items:center;
		overflow:hidden;
		visibility: hidden;
		background-color: transparent;
		background-image: url(../images/mobile-menu/images/mobile-slideout-logo.png);
		background-image: contain;
		height: 200px;

		.mob-slide-logo {
			flex-shrink:0;
			min-width:100%;
			min-height:100%;
		}
	}
}

.collapse-content {
	margin: 0 auto;

	@media only screen and (max-width: 767px) {
		position: relative;
		top: 15px;
	}
}

div.fill {
    position:relative;
    border:1px dashed red;
    display:flex;
    justify-content:center;
    align-items:center;
    overflow:hidden;
}
div.fill img {
    flex-shrink:0;
    min-width:100%;
    min-height:100%
}
/* 
#headerNav ul li:last-child {
	@media only screen and (max-width: 767px) {
		background-image: url(../images/mobile-menu/images/mobile-slideout-logo.png);
		background-size: 100% 100%;
		background-repeat: no-repeat;
		background-position: left top;
		margin-right: 0;
	}
} */

.collapse-content::after {
	@media only screen and (max-width: 767px) {
		border-radius: 50px;
	}
}

.nav-item {
	@media only screen and (max-width: 767px) {
		.nav-link {
			width: 80%;
			margin: 0 auto;
			display: block;
			/* border-bottom: dotted 1px #000; */
		}
	}
}

#headerNav ul li.nav-tile {
	@media only screen and (max-width: 767px) {
		width: 100%;
		float: none;
		background-color: #fff;
	}
}

#headerNav ul li.nav-tile .nav-link {
	text-decoration: none;
	background: url(../images/navTile_up.png) repeat-x top left;
	text-align: center;
	font-size: 10px/12px;
	float: left;
	margin: 0px;
	height: 37px;
	cursor: pointer;
	width: 178px;
	padding-top: 9px;
	padding-right: 0px;
	padding-bottom: 0px;
	padding-left: 0px;
	color: #000;
	text-decoration: none;

	@media only screen and (max-width: 952px) {
		width: auto;
		padding: 9px 25px;
	}

	@media only screen and (max-width: 767px) {
		text-align: left;
		background-image: none;
		width: 90% !important;
		margin: 0 auto !important;
		display: block !important;
		float: none;
		padding: 9px 5px;
		border-bottom: 1px dotted #000;
		/* background-color: #345b55; */
	}
}

#headerNav li.nav-tile a:hover {
	background-image: url(../images/navTile_over.png);
	background-repeat: repeat-x top;
	color: #fff;

	@media only screen and (max-width: 767px) {
		background-image: none;
		background-color: #345b55;
		width: 100%;
		text-align: left;
	}
}


#headerNav ul li.nav-tile2 {
	@media only screen and (max-width: 767px) {
		width: 100%;
		float: none;
		background-color: #fff;
	}
}

#headerNav ul li.nav-tile2 .nav-link {
	text-decoration: none;
	background: url(../images/navTile_up.png) repeat-x top left;
	font-size: 10px/12px;
	float: left;
	text-align: center;
	margin: 0px;
	height: 37px;
	cursor: pointer;
	width: 178px;
	padding-top: 9px;
	padding-right: 0px;
	padding-bottom: 0px;
	padding-left: 0px;
	color: #000;

	@media only screen and (max-width: 952px) {
		width: auto;
		padding: 9px 25px;
	}

	@media only screen and (max-width: 767px) {
		background-image: none;
		display: block !important;
		width: 90%;
		margin: 0 auto;
		padding: 9px 5px;
		text-align: left;
		float: none;
		border-bottom: 1px dotted #000;
	}
}

#headerNav li.nav-tile2 .nav-link:hover {
	background-image: url(../images/navTile_over.png);
	background-repeat: repeat-x;
	background-position: top;
	color: #fff;

	@media only screen and (max-width: 767px) {
		background-image: none;
		background-color: #325b55;
		display: block !important;
		width: 90%;
		text-align: left;
		color: #fff;
	}
}

.leftNavBtn {
	background-color: #fff;
}


#headerNav ul li.leftNavBtn .nav-link {
	text-align: center;
	text-decoration: none;
	background-image: url(../images/navLeft_up.png);
	background-repeat: no-repeat;
	background-position: top left;
	font-size: 10px/12px;
	height: 37px;
	width: 178px;
	cursor: pointer;
	padding-top: 9px;
	padding-right: 0px;
	padding-bottom: 0px;
	padding-left: 0px;
	color: #000;
	border: 0px solid #fff;
	display: inline-block;

	@media only screen and (max-width: 952px) {
		background-image: none;
		
		width: auto;
		padding: 9px 25px;
	}

	@media only screen and (max-width: 767px) {
		text-align: left;
		width: 90%;
		float: none;
		margin: 0 auto;
		display: block;
		padding: 9px 5px;
		border-bottom: dotted 1px #000;
	}
}

#headerNav ul li.leftNavBtn {
	@media only screen and (max-width: 952px) {
		
	}

	@media only screen and (max-width: 767px) {
		margin: 0;
		width: 100%;
		text-align: left;
	}
}

/* Active Nav Class for Home */
#headerNav ul li.leftNavBtn.active .nav-link {
	background-image: url(../images/navLeft_over.png);
	color: #fff;

	@media only screen and (max-width: 952px) {
		background-image: url(../images/navTile_over.png);
		background-repeat: repeat-x;
		background-position: top;
	}

	@media only screen and (max-width: 767px) {
		background-image: none;
		background-color:#345b55;
		margin-top: 10px;
	}
}

#headerNav ul li.rightNavBtn.active .nav-link {
	background-image: url(../images/navRight_over.png);
	color: #fff;

	@media only screen and (max-width: 952px) {
		background-image: url(../images/navTile_over.png);
		background-repeat: repeat-x;
		background-position: top;
	}

	@media only screen and (max-width: 767px) {
		background-image: none;
		background-color:#345b55;
		margin: 10px auto 0 auto;
		width: 90%;
	}
}

/* Active Class for Interface Page */
#headerNav ul li.nav-tile.active .nav-link {
	background-image: url(../images/navTile_over.png);
	background-repeat: repeat-x;
	background-position: top;
	color: #fff;

	@media only screen and (max-width: 767px) {
		background-image: none !important;
		background-color: #345b55;
	}
}

/* Active Class for Graphics Page */
#headerNav ul li.nav-tile2.active .nav-link {
	background-image: url(../images/navTile_over.png);
	color: #fff;

	@media only screen and (max-width: 767px) {
		background-image: none;
		background-color: #345b55;
	}
}

#headerNav ul li.leftNavBtn .nav-link:hover,
a.leftNavBtn:hover {
	background-image: url(../images/navLeft_over.png);
	background-repeat: no-repeat;
	background-position: left;
	color: #fff;
	cursor: pointer;

	@media only screen and (max-width: 952px) {
		background-image: url(../images/navTile_over.png);
		background-repeat: repeat-x;
		background-position: top;
	}

	@media only screen and (max-width: 767px) {
		background-image: none;
		/* background-image: url(../images/navTile_over.png); */
		background-color: #345b55;
		margin: 0 auto;
		float: none;
		padding: 9px 5px;
		display: block;
	}
}
z
#headerNav ul li.rightNavBtn .nav-link {
	text-decoration: none;
	background-image: url(../images/navRight_up.png);
	background-repeat: no-repeat;
	background-position: top right;
	/* background-size: contain; */
	font-size: 10px/12px;
	float: left;
	margin: 0px;
	color: #fff;
	height: 37px;
	width: 177px;
	cursor: pointer;
	padding: 9px 0px 0px 0px;
	float: left;
	color: #000000;
	display: inline;

	@media only screen and (max-width: 952px) {
		background-image: none;
		width: auto;
		padding: 9px 25px;
	}

	@media only screen and (max-width: 767px) {
		text-align: left;
		background-image: none;
		width: 90%;
		margin: 0 auto;
		padding: 9px 5px;
		float: none;
		border-bottom: 1px dotted #000;
		display: block;
	}
}

#headerNav ul li.rightNavBtn {
	@media only screen and (max-width: 767px) {
		background-color: #fff;
		width: 100%;
	}
}

#headerNav ul li.rightNavBtn .nav-link:hover {
	background-image: url(../images/navRight_over.png);
	background-repeat: repeat-x;
	background-position: top right;
	color: #fff;
	cursor: pointer;

	@media only screen and (max-width: 952px) {
		background-image: url(../images/navTile_over.png);
		background-repeat: repeat-x top;
	}

	@media only screen and (max-width: 767px) {
		background-image: none;
		background-color: #345b55;
		float: none;
		width: 90%;
		margin: 0 auto;
		display: block;
	}
}

#headerNav ul li.divider {
	/* background: url(../images/nav-div.png) repeat-x top right; */
	width: 2px;
	height: 37px;
}

.headerRight {
	background-image: url(../images/HeadAngleRight.png);
	width: 121px;
	height: 162px;
	background-position: top right;
	float: right;

	@media only screen and (max-width: 952px) {
		display: none;
		width: 0;
	}
}

@media (min-width: 768px) {
	.navbar-expand-lg {
		flex-wrap: nowrap;
		justify-content: flex-start;
	}

	.navbar-expand-lg .navbar-nav {
		flex-direction: row;
	}
}

.navbar.navbar-expand-md {
	margin: 0;
	padding: 0;
	box-shadow: none;

	@media only screen and (max-width: 952px) {
		margin: 0;
		padding: 0;
	}
}

.container-fluid {
	margin: 0 !important;
	padding: 0 !important;

	@media only screen and (max-width: 952px) {
		margin: 0;
		padding: 0;
	}
}

.navbar button.navbar-toggler {
	height: 28px;
	margin-top: 2px;
	background-color: #345b55;
	background: linear-gradient(to bottom,  #031b18 0%,#30594d 51%,#23453d 55%,#4f8271 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	border: 2px solid #000;
	position: relative;
	left: 80%;
	padding-top: 2px;
	padding-bottom: 2px;
	box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}

[type=button]:not(:disabled) .fas.fa-bars,
[type=reset]:not(:disabled) .fas.fa-bars,
[type=submit]:not(:disabled) .fas.fa-bars,
button:not(:disabled) .fas.fa-bars {
	color: #fff;
}


.fa-solid,
.fas {
	font-family: "Font Awesome 6 Free";
	font-weight: 900;
}

/*********************************************************
Begin Banner Area
******************************************************/
#flashHolder {
	margin: 0 auto;
	padding: 0px 0px 0px 8px;
	height: 350px;
	z-index: 1;
	width: 953px;
	position: relative;
    top: 30px;

	iframe {
		width: 100%;
		height: 100%;
		border: none;
	}

	.desktop-animation,
	img {
		overflow: hidden;
		width: 3822px;
        height: 350px;
        margin-top: -90px;
        margin-left: -1445px;
        padding: 0;
		

		@media only screen and (max-width: 952px) {
			
		}
	}

	@media only screen and (max-width: 952px) {
		width: 100%;
	}
}

#flashHolder::after {
	background-image: url(../images/radial-lithe-tres.png);
	background-repeat: repeat-x;
}

.mobFlashHolder {
	width: 100%;
	height: auto;

	iframe {
		width: 100%;
		height: 100%;
		border: none;
	}
}

#flashHolder div,
.mobFlashHolder div {
	border: 0px red solid;
	margin: 0 auto;
}

.bannerHolder {
	background-position: top center;
	background-repeat: no-repeat;
	text-align: center;
	border: 0px #000 solid;
	/* max-width: 1550px !important; */
	width: 100% !important;
	height: 83px;
	position: relative;
	top: -1px;
	overflow: hidden;

	@media only screen and (max-width: 767px) {
		height: auto;
		margin-top: 15px;
	}
}

.bannerHolder.graphics {
	background-image: url(../images/printHolder.png);

	@media only screen and (max-width: 767px) {
		background-image: none;
	}
}

.bannerHolder.interface {
	background-image: url(../images/interfaceBanner.png);

	@media only screen and (max-width: 767px) {
		background-image: none;
	}
}

.bannerHolder.experience {
	background-image: url(../images/Experiencebanner.png);

	@media only screen and (max-width: 767px) {
		background-image: none;
	}
}

.bannerHolder.contact { 
	background-image: url(../images/contactBanner2.png);

	@media only screen and (max-width: 767px) {
		background-image: none;
	}
}

#projHolder {
	border: 0px #000 solid;
	height: 284px;

	@media only screen and (max-width: 952px) {
		height: auto;
	}
}

#slideBox {
	width: 953px;
	height: 250px;
}

#circle-nav {
	text-align: center;
}

#copyFrame {
	max-width: 953px;
	width: 100%;
	margin: 0 auto;
	background-position: center top;
	background-repeat: repeat-y;
	height: auto;
	z-index: 5;
	border: 0px #000 solid;
	position: relative;

	@media only screen and (max-width: 767px) {
		position: static;
	}
}

 .home  #copyFrame {
	@media only screen and (min-width: 953px) {
		top: -147px;
	}
}

#copyFrame.sub {
	position: relative;

	@media only screen and (max-width: 767px) {
		position: static;
	}
}


.copyTop {
	max-width: 953px;
	border: 0px red solid;

	@media only screen and (min-width: 953px) {
		height: 90px;
	}

	@media only screen and (max-width: 952px) {}
}

.copyTopLCXXX {
	background-image: url(../images/frameTopLeft.png);
	background-repeat: no-repeat;
	height: 85px;
	width: 129px;
	margin: 0px;
	float: left;
}

.copyTopLC {
	background-image: url(../images/frameTopLeft.png);
	background-repeat: no-repeat;
	background-position: top left;
	height: 90px;
	width: 154px;
	float: left;
	margin: 0px;

	@media only screen and (max-width: 952px) {
		display: none;
	}
}

.copyTopTile {
	background-image: url(../images/frameTopRight.png);
	background-repeat: repeat-x;
	background-position: top left;
	width: 799px;
	height: 90px;
	border: 0px #fff solid;
	float: right;
	margin: 0px;
	margin: 0px;

	@media only screen and (max-width: 952px) {
		display: none;
	}
}

.copyTopRC {
	background-image: url(../images/frameTopRight.png);
	background-repeat: no-repeat;
	height: 90px;
	width: 157px;
	float: right;
	background-position: top right;
	background-repeat: no-repeat;
	border: 0px #fff solid;
	margin: 0px;

	@media only screen and (max-width: 952px) {
		display: none;
	}
}

.subcopyTopLC {
	background-image: url(../images/frameTopLeft.png);
	background-repeat: no-repeat;
	background-position: top left;
	height: 90px;
	width: 154px;
	float: left;
	margin: 0px;

	@media only screen and (max-width: 952px) {
		display: none;
	}
}


.subcopyTopTile {
	background-image: url(../images/frameTopRight.png);
	background-repeat: repeat-x;
	background-position: top left;
	width: 799px;
	height: 90px;
	border: 0px #fff solid;
	float: right;
	margin: 0px;
	margin: 0px;

	@media only screen and (max-width: 952px) {
		background-image: url(../images/mobile-copy-top-tile.png);
		background-repeat: repeat-x;
		background-position: top left;
		width: 100%;
	}
}

.subcopyTopRC {
	height: 90px;
	width: 157px;
	float: right;
	background-position: top right;
	background-repeat: no-repeat;
	border: 0px #fff solid;
	margin: 0px;
}

.projcopyTopLC {
	background-image: url(../images/frameTopLeft.png);
	background-repeat: no-repeat;
	height: 90px;
	width: 154px;
	float: left;
	margin: 0px;
}

.projcopyTopTile {
	background-image: url(../images/frameTopRight.png);
	background-repeat: repeat-x;
	background-position: top left;
	width: 799px;
	height: 90px;
	border: 0px #fff solid;
	float: right;
	margin: 0px;
}

.projcopyTopRC {
	background-image: url(../images/framePrSlTopRight.png);
	background-repeat: no-repeat;
	height: 90px;
	width: 157px;
	float: right;
	background-position: top right;
	background-repeat: no-repeat;
	border: 0px #fff solid;
	margin: 0px;
}

.copyMainTile {
	background-image: url(../images/copy_Y_Tile.png);
	background-position: center top;
	background-repeat: repeat-y;
	width: 953px;
	height: 100%;

	@media only screen and (max-width: 952px) {
		background-image: url(../images/mobile-home-content-tile2.png);
		background-repeat: repeat-x;
		background-position: top left;
		background-color: #fff;
		width: 100%;
		padding-top: 60px;
	}
}

.homeBtns3 {
	display: flex;
    flex-wrap: wrap;
	margin: 0 auto;
	width: 100%;
	max-width: 600px;
	padding: 0;
	font-size: 13px;
	border: 0px red solid;
	left: 12px;
	color: #000;
	text-align: center;

	@media only screen and (max-width: 952px) {
		max-width: 80%;
		padding-left: 10px;
		top: 80px;
		background-color: #fff;
	}

	@media only screen and (max-width: 767px) {
		text-align: center;
		max-width: 100%;
	}
}

.content {
	margin-left: 0px;
	margin: 0 auto;
	padding: 0px;
	width: 642px;
	font-size: 13px;
	border: 0px red solid;
	color: #000;

	@media only screen and (max-width: 952px) {
		width: 100%;
		padding-left: 10px;
	}
}

.content .blurbage {
	padding: 0px 30px 0px 30px;
	background-color: #fff;
	max-width: 690px;
    margin: 0 auto;

	a.togglemodal {
		display: block;
		color: #a5251e !important;
		font-weight: bold;
	}
}

.content #knuckles {
	margin-top: 30px;
	left: 8px;
	color: #666666;
	background-color: #ffffff;

	@media only screen and (max-width: 952px) {
		left: 0;
	}

	@media only screen and (max-width: 767px) {
		margin-top: 20px;
	}
}

.content #knuckles span {
	font-size: 18px;
	font-weight: bold;
}

.content a:link {
	text-decoration: none;
}

.content a:hover {
	text-decoration: underline;
}

.content a:visited {
	text-decoration: none;
}

.copyBot {
	height: 30px;
	width: 953px;
	border: 0px #000 solid;

	@media only screen and (max-width: 952px) {
		width: 100%;
	}
}

.copyBotLC {
	background-image: url(../images/frameBotLeft.png);
	background-position: left top;
	background-repeat: no-repeat;
	height: 95px;
	width: 154px;
	float: left;
	margin: 0px;

	@media only screen and (max-width: 952px) {
		display: none;
		width: 0;
	}
}

.copyBotTile {
	background-image: url(../images/frameBotTile.png);
	background-position: center top;
	background-repeat: repeat-x;
	height: 95px;
	max-width: 799px;
	width: 100%;
	float: left;
	margin: 0px;

	@media only screen and (max-width: 952px) {
		max-width: 100%;
	}
}

.outerWrap {
	min-height: 100%;
	height: auto !important;
	height: 100%;
}

.footer {
	background-color: #000;
	text-align: center;
	font-size: 11px;
	margin: 40px 0px 0px 0px;
	height: 80px;
}

.footer a:link {
	color: #fff;
}

.footer a:hover {
	color: #fff;
}

.footer a:visited {
	color: #fff;
}

.copyBotRC {
	background-image: url(../images/frameBotRight.png);
	margin: 0px;
	background-position: top right;
	background-repeat: no-repeat;
	height: 95px;
	width: 157px;
	float: right;

	@media only screen and (max-width: 952px) {
		display: none;
		width: 0;
	}
}

.projThumbs {
	text-align: left;
	color: #0E1613;
	font-size: 13px;
	padding: 10px;
	margin: 20px 0px 10px 30px;
	border-bottom: 1px dotted #85908F;
	margin: 0px;
}

.projThumbs img {
	margin-left: 8px;
}

.projThumbs table td {
	color: #0E1613;
	padding: 10px 0px 10px 0px;
}

#contactSubmit {
	text-align: right;
}

.projThumbs a:link {
	color: #0E1613;
	text-decoration: none;
}

.projThumbs a:hover {
	color: #0E1613;
	text-decoration: underline;
}

.projThumbs a:visited {
	color: #0E1613;
	text-decoration: none;
}

.projThumbs h1 {
	margin: 0px 0px 0px 0px;
	display: inline;
	font-size: 20px;
	font-weight: bold;
	color: #0E1613;
}

#projDescript {
	float: left;
	color: #dbd5c3;
	border: 0px solid #fff;
	width: 320px;
	height: 200px;
	margin: 17px 0px 0px 130px;
	padding: 5px 5px 5px 5px;
	text-align: left;
	font-size: 12px;
	z-index: 2;
	filter: progid:DXImageTransform.Microsoft.dropshadow(OffX=5, OffY=5,
			Color='black', Positive='true')
}

#projDescript h1 {
	margin: 0px 0px 0px 0px;
	display: inline;
	font-size: 21px;
	font-weight: bold;
	color: #9C9062;
	z-index: 2;
}

#projDescript .details {
	margin: 10px 0 0 33px;
}

#projDescript .details h1 {
	margin: 0px 0px 0px 0px;
	display: inline;
	font-size: 15px;
	font-weight: bold;
	color: #dbd5c3;
}

#projPic {
	float: left;
	margin: 0px 0px 0px 0px;
	width: 425px;
}

#heading-div {
	position: relative;
	top: -30px;
	text-align: center;
}

.headerMain {
	position: relative !important;

	span.contact-link {
		margin-right: 25px;
		position: absolute !important;
		right: 20px;
		display: block;
		background-color: #ccc;
		height: 30px;
		width: auto;
		text-align: center;
		color: #090909;

		a {
			margin-right: 15px;
			color: #a5251e;
			font-weight: bold;
			font-size: .8em;
		}

		img {
			margin: -3px 3px 0 0;
		}

		&::before {
			position: absolute;
			left: -32px;
			top: 0;
			content: "";
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 0 32px 30px 0;
			border-color: transparent #ccc transparent transparent;
		}

		&::after {
			border-color: #ccc transparent transparent;
			border-style: solid;
			border-width: 30px 32px 0 0;
			content: "";
			height: 0;
			position: absolute;
			right: -31px;
			top: 0;
			width: 0;
		}

		@media only screen and (max-width: 767px) {
			display: none;
		}
	}
}

.contactForm {
	text-align: left;
	color: #0E1613;
	position: relative;
	top: -30px;
	left: 40px;
	font-size: 13px;
	padding: 0px 0px 0px 0px;
	margin: 0px 0px 0px 0px;
	border-bottom: 0px dotted #85908F;
	height: auto;
	margin: 0px;
}


.contactForm table td {
	color: #0E1613;
	padding: 10px 0px 10px 0px;
}

#contactSubmit {
	text-align: right;
}
.contactForm a:link {
	color: #cdc6a9;
	text-decoration: none;
}

.contactForm a:hover {
	color: #cdc6a9;
	text-decoration: underline;
}

.contactForm a:visited {
	color: #cdc6a9;
	text-decoration: none;
}

input {
	background-image: url(../images/inputBG.png);
	background-position: top left;
	background-repeat: repeat-x;
	border: 1px #050807 solid;
}

textarea {
	background-image: url(../images/inputBG.png);
	background-position: top left;
	background-repeat: repeat-x;
	border: 1px #050807 solid;
}

.launch {
	height: 48px;
	background-image: url(../images/launchBG.png);
	background-repeat: no-repeat;
	font-weight: bold;
	border: 0px solid #fff;
	text-align: center;
	padding-right: 20px;
	font-size: 14px;
}

.serviceBullets {
	background-image:
		url(../images/aboutSkillsTopRight.png),
		url(../images/aboutSkillsTop.png),
		url(../images/aboutSkillsBottomRight.png),
		url(../images/aboutSkillsBot.png),
		url(../images/aboutSkillsTileRight.png),
		url(../images/aboutSkillsTile.png);
	background-repeat: no-repeat, no-repeat, no-repeat, no-repeat, repeat-y, repeat-y;
	background-position: top right, top left, bottom right, bottom left, top right, top left;
	max-width: 628px;
	width: 100%;
	margin: 0 auto;
	font-size: 14px;
	padding: 10px;

	h2 {
		font-size: 1.2em;
		margin-left: 30px;
		margin-top: 20px;

		span {
			font-size: .9em;
		}
	}

	h3 {
		font-size: 1em;
		margin-left: 30px;
	}

	table {
		width: 90%;
		margin: 0 auto;
	}

	@media only screen and (max-width: 767px) {
		width: 100%;
		font-size: 90%;

		h2 {
			margin-top: 20px;
		}

		h2,
		h3 {
			font-size: 100%;
		}

		table { 
			padding: 0;
				margin: 0;
				font-size: 80%;
		}

		ul {
			margin: 20px 0 10px 10px;

			li {
				padding: 0;
				margin: 0;
				font-size: 100%;
			}
		}
	}
}

.content .blurbage,
.content .serviceBullets {
	@media only screen and (max-width: 767px) {
		padding:  10px;
	}
}

.content .serviceBullets {
	@media only screen and (max-width: 767px) {
		margin: 0 auto;
		position: relative;
		left: -10px;
	}
}

.serviceBullets.experience li {
	margin: 0;
}

.aboutSkillsTop {
background-repeat: no-repeat;
	background-position: left top;
	width: 628px;
	height: 28px;

	@media only screen and (max-width: 767px) {
		padding: 0 10px;
	}
}

.aboutSkillsTileLeft {
	padding: 0px 0px 0px 30px;
	border-right: 1px #ccc solid;
}

.aboutSkillsTileRight {
	padding: 0px 0px 0px 30px;
}

.aboutSkillsBot {
	background-image: url(../images/aboutSkillsBot.png);
	background-repeat: no-repeat;
	background-position: left bottom;
	width: 628px;
	height: 34px;
}

.serviceBullets ul li {
	line-height: 30px;
	list-style-type: disc;
}

.serviceBullets td {
	vertical-align: top;
	font-size: 14px;
}

/************menu items**********************************************/
#menubar {
	position: absolute;
	width: 450px;
	left: 5px;
	top: 55px;
	background-color: #fff;
	border: 5px solid #AD5800;
	padding: 2px;
	z-index: 6;


}

#menubar a {
	color: #63625D;
	letter-spacing: 1px;
	border: 1px solid #F9F1C8;
	padding-left: 5px;
	padding-right: 5px;
	text-decoration: none;
	z-index: 6;
}

#menubar a.active {
	border-bottom-color: #CCC599;
	border-right-color: #CCC599;
	border-left-color: #FFFFFF;
	border-top-color: #FFFFFF;
	z-index: 6;
}

.menu .options {
	height: 50px;
	margin-right: 1px;
	font-size: 14px;
	margin-bottom: 1px;
	border: 0px solid #63625D;
	background-image: url(../images/dropDownBgLft.png);
	background-position: left;
	background-repeat: no-repeat;
	font-weight: bold;
	z-index: 6;
}

td.dropTextBorder {
	border-right: 1px solid #000;
}

.menu #optionLeft {
	background-image: url(../images/dropDownBgLft.png);
	background-position: left bottom;
	padding: 0px 0px 0px 28px;
	background-repeat: no-repeat;
}

.menu #optionRight {
	background-image: url(../images/dropDownBgRft.png);
	background-position: left bottom;
	padding: 0px 0px 0px 18px;
	background-repeat: no-repeat;
}

.menu a {
	color: #000;
	display: block;
	padding: 2px 10px;
	text-decoration: none;

	z-index: 6;
}

.menu a:visited {
	color: #000;
	display: block;
	padding: 2px 10px;
	text-decoration: none;
	background-color: #ffffff;
	z-index: 6;
}

.menu a:hover {
	z-index: 6;
	background-color: #ccc;
	color: #000;
}

/******************************************************************************/
@media screen {
	div#preloader {
		position: absolute;
		left: -9999px;
		top: -9999px;
	}

	div#preloader img {
		display: block;
	}
}

@media print {
	div#preloader,
	div#preloader img {
		visibility: hidden;
		display: none;
	}
}

/* Slider */
#loopedSlider {
	margin: 0 auto;
	height: 342px;
	width: 1207px;
	position: relative;
	left: -130px;
	top: -60px;
	clear: both;
	background-image: url(../images/slideBG.png);
	background-position: top center;
	background-repeat: no-repeat;
	border: 0px #000 solid;
	padding: 10px 0px 0px 10px;

	.carousel-control-prev {
		margin-top: -71px;
		margin-left: -131px;

		.visually-hidden {
			display: none !important;
		}

		@media only screen and (max-width: 952px) {
			margin-left: 0;
			width: 40px;
			margin-top: 0;
			background-color: #000;
			border-right: #65603d 2px solid;
			opacity: 1;
		}

		@media only screen and (max-width: 767px) {
			margin-left: 0;
			width: 40px;
		}
	}

	.carousel .carousel-control-prev-icon {
		background-image: url(../images/prev.png);
		width: 30px;
		width: 32px;
		height: 42px;
		opacity: 1;

		@media only screen and (max-width: 767px) {
			margin-right: 0;
		}
	}

	.carousel .carousel-control-next {
		margin-top: -71px;
		margin-right: -120px;

		.visually-hidden {
			display: none !important;
		}

		@media only screen and (max-width: 952px) {
			margin-top: 0;
			margin-right: 0;
			width: 40px;
			background-color: #000;
			opacity: 1;
			border-left: #65603d 2px solid;
		}

		@media only screen and (max-width: 767px) {
			margin-left: 0;
			width: 40px;
		}
	}

	.carousel .carousel-control-next-icon {
		background-image: url(../images/next.png);
		width: 32px;
		height: 42px;
		opacity: 1;
	}

	@media only screen and (max-width: 952px) {
		width: 100%;
		margin-left: 0;
		background-image: url(../images/mobile-slide-bg.png);
		background-color: #10181b; 
		background-size: cover;
		/* opacity: .7; */
		left: 0;
		/* margin: 5px 5px 5px 5px; */
		top: 0;
		height: auto;
		padding-left: 0;
		padding-top: 0;
		padding-bottom: 0;
	}

	@media only screen and (max-width: 767px) {
		height: auto;
	}
}

#loopedSlider .carousel-control-prev,
#loopedSlider .carousel-control-next {
	opacity: 1;

	&:hover {
		opacity: .5;
	}
}

#loopedSlider .carousel-fade .carousel-inner {
	color: #fff;
	top: 80px;
	height: 259px;
	max-width: 690px;
	margin: 0 auto;
	overflow: visible;

		.carousel-indicators {
			margin-bottom: 5px;
		
		@media only screen and (max-width: 952px) {
			margin-bottom: -50px;
		}
	}

	a img:hover {
		-webkit-filter: brightness(.5);
   		filter: brightness(.5);
	}

	.verbiage {
		overflow: hidden;
		height: 190px;

		a.togglemodal {
			color: #a99e75 !important;
			text-decoration: underline;

			&:hover {
				text-decoration: none;
			}

			&:visited {
				color: #a99e75;
				text-decoration: underline !important;
			}
		}


		.verbiage-overflow {
			height: 190px;
			overflow-y: scroll;
			scrollbar-color:  #aa9d69 #335c56;
			scrollbar-width: 10px;
		}

		h2 {
			margin: 0 50px 0 0;
		}

		h3 {
			margin: 10px 50px 0 0;
		}
		
		h2, h3 {
			font-size: 1em;
			font-weight: bold;

			@media only screen and (max-width: 952px) {
				width: 90%;
				min-width: 90%;
				min-height: auto;
				display: block;
				margin: 10px auto 0 auto;
			}
		}
		
		p {
			font-size: .9em;

			@media only screen and (max-width: 952px) {
				width: 90%;
				min-width: 90%;
				min-height: auto;
				display: block;
				margin: 0 auto;
			}
		}

		@media only screen and (max-width: 952px) {
			margin: 10px 50px 0 50px;
		}
	}

	@media only screen and (max-width: 952px) {
		padding: 40px;
		display: flex;
		top: 0;
		max-width: 100%;
		top: 0;
		height: auto;

		img {
			flex-shrink: 0;
			width: 90%;
			min-width: 90%;
			min-height: auto;
			display: block;
			margin: 0 auto;
		}

		.verbiage {
			margin: 10px auto;

			p {
				line-height: 1.5em;
			}
		}
	}


	@media only screen and (max-width: 767px) {

	}

	@media only screen and (min-width: 576px) {
		.col-sm-6 {
			max-width: 100%;
		}
	}
}

//update with my bg 11/11/24
@media only screen and (min-width: 953px) {
	.carousel .carousel-indicators li {
		width: 30px  !important;
		height: 30px  !important;
		background-image: url(../images/pagination.png);
		background-position: top center;
		background-color: transparent;
		opacity: 1 !important;
		margin-right: 15px;

	}

	.carousel .carousel-indicators li.active {
		background-image: url(../images/pagination.png);
		background-position: bottom center;
		background-color: transparent;
		opacity: 1 !important;
	}
}

/*OLD*/
ul.pagination {
	list-style-type: none;
	margin: 0;
	padding: 0;
	margin: 9px auto;
	border: 0px red solid;
	width: 376px;
	position: absolute;
	top: 302px;
	left: 487px;
	text-align: center;
}

ul.pagination a {
	display: block;
	width: 24px;
	padding-top: 25px;
	height: 0;
	overflow: hidden;
	background-image: url(../images/paginationHomeSm.png);
	background-position: 0 0;
	background-repeat: no-repeat;
}

a.previous {
	position: absolute;
	top: 165px;
	left: 190px;
}

a.next {
	position: absolute;
	top: 165px;
	right: 143px;
}

#technoBox {
	position: relative;
	margin: 0px auto;
	width: 1659px;
	height: 500px;
}

#leftTechno {
	float: left;
	border: 0px red solid;
	width: 551px;
	-moz-opacity: 1.00;
	filter: alpha(opacity=100);
	opacity: 1.00;
	height: 436px;
}

#rightTechno {
	float: right;
	-moz-opacity: 1.00;
	filter: alpha(opacity=100);
	opacity: 1.00;
	border: 0px red solid;
	top: 60px;
	width: 551px;
	height: 416px;
}

#background-wrap {
    bottom: 0;
	left: 0;
	padding-top: 50px;
	position: fixed;
	right: 0;
	top: 0;
	z-index: -1;
	height: 215px;
}

/* CLOUD KEYFRAMES */
@-webkit-keyframes animateCloud {
    0% {
        margin-left: -1500px;
    }
    100% {
        margin-left: 100%;
    }
}

@-moz-keyframes animateCloud {
    0% {
        margin-left: -1500px;
    }
    100% {
        margin-left: 100%;
    }
}

@keyframes animateCloud {
    0% {
        margin-left: -1500px;
    }

    100% {
        margin-left: 100%;
    }
}

/* CLOUD ANIMATIONS */
#background-wrap-graphics {
.x1 {
	-webkit-animation: animateCloud 35s linear infinite;
	-moz-animation: animateCloud 35s linear infinite;
	animation: animateCloud 35s linear infinite;
	-webkit-transform: scale(0.65);
	-moz-transform: scale(0.65);
	transform: scale(0.65);

	.cloud {
		background-image: url(../images/bg-bells-whistles/thinCloud1.png);
		background-repeat: no-repeat;
		background-size: contain;
		background-position: top center;
		height: 320px;
		width: 1000px;
		position: relative;
		top: 250px;
		opacity: .9;
		border: 3px solid red;
	}
}

.x2 {
	-webkit-animation: animateCloud 40s linear infinite;
	-moz-animation: animateCloud 40s linear infinite;
	animation: animateCloud 40s linear infinite;
	-webkit-transform: scale(0.3);
	-moz-transform: scale(0.3);
	transform: scale(0.3);

	.cloud {
		background-image: url(../images/bg-bells-whistles/thinCloud2.png);
		background-repeat: no-repeat;
		background-size: contain;
		height: 230px;
		position: relative;
		width: 1500px;
		opacity: .6;

		@media only screen and (max-width: 952px){
		}
	}
}

.x3 {
	-webkit-animation: animateCloud 45s linear infinite;
	-moz-animation: animateCloud 45s linear infinite;
	animation: animateCloud 45s linear infinite;
	-webkit-transform: scale(0.5);
	-moz-transform: scale(0.5);
	transform: scale(0.5);

	.cloud {
		background-image: url(../images/bg-bells-whistles/thinCloud3.png);
		background-repeat: no-repeat;
		background-position: top left;
		background-size: contain;
		height: 150px;
		position: relative;
		top: -450px;
		width: 1800px;
		opacity: .7;
	}
}

.x4 {
	-webkit-animation: animateCloud 20s linear infinite;
	-moz-animation: animateCloud 20s linear infinite;
	animation: animateCloud 20s linear infinite;
	-webkit-transform: scale(0.4);
	-moz-transform: scale(0.4);
	transform: scale(0.4);

	.cloud {
		background-image: url(../images/bg-bells-whistles/thinCloud4.png);
		background-repeat: no-repeat;
		background-position: top left;
		background-size: contain;
		height: 300px;
		position: relative;
		top: -1550px;
		width: 1500px;
		opacity: .6;
	}
}
}

.disclaimer-container {
	a { 
		display: block;
		text-align: center;
		max-width: 750px;
		margin: 0 auto;

		img {
			max-width: 80px;
			margin: 20px 0;
		}
	}
		
	.disclaimer { 
		display: block;
		max-width: 750px;
		margin: 0 auto;
		text-align: left;
		color: white;
		font-size: .7em;

		img {
			max-width: 90px;
			margin: 0 auto;
		}
	}
}

