
// CONTACT ANIMATION
/* CONTACT KEYFRAMES */
@-webkit-keyframes animateLetter {
    0% {
        margin-left: -1500px;
    }
    100% {
        margin-left: 100%;
    }
}

@-moz-keyframes animateLetter {
    0% {
        margin-left: -1500px;
    }
    100% {
        margin-left: 100%;
    }
}

@keyframes animateLetter {
    0% {
        margin-left: -1500px;
    }

    100% {
        margin-left: 100%;
    }
}

@-webkit-keyframes animateLetter {
    0% {
        margin-left: -1500px;
    }
    100% {
        margin-left: 100%;
    }
}

@-moz-keyframes animateLetter {
    0% {
        margin-left: -1500px;
    }
    100% {
        margin-left: 100%;
    }
}

@keyframes animateLetter {
    0% {
        margin-left: -1500px;
    }

    100% {
        margin-left: 100%;
    }
}

@-webkit-keyframes animateLetter2 {
    0% {
        margin-left: 100%;
    }
    100% {
        margin-left: -2000px;
    }
}

@-moz-keyframes animateLetter2 {
    0% {
        margin-left: 100%;
    }
    100% {
        margin-left: -2000px;
    }
}

@keyframes animateLetter2 {
    0% {
        margin-left: 100%;
    }
    100% {
        margin-left: -2000px;
    }
}

@-webkit-keyframes animateLetter2 {
    0% {
        margin-left: 100%;
    }
    100% {
        margin-left: -2000px;
    }
}

@-moz-keyframes animateLetter2 {
    0% {
        margin-left: 100%;
    }
    100% {
        margin-left: -2000px;
    }
}

@keyframes animateLetter2 {
    0% {
        margin-left: 100%;
    }
    100% {
        margin-left: -2000px;
    }
}

/* CONTACT ANIMATIONS */
@media only screen and (min-width: 953px) {
.x1 {
	-webkit-animation: animateLetter 5s linear infinite;
	-moz-animation: animateLetter 5s linear infinite;
	animation: animateLetter 5s linear infinite;
	-webkit-transform: scale(0.45);
	-moz-transform: scale(0.45);
	transform: scale(0.45);

	.mail {
		background-image: url(./images/animated-mail-1.png);
		background-repeat: no-repeat;
		background-size: contain;
		background-position: top center;
		height: 131px;
		width: 180px;
		position: relative;
        z-index: 10;
		top: 50px;
		opacity: .4;
	}
}

.x2 {
	-webkit-animation: animateLetter2 6s linear infinite;
	-moz-animation: animateLetter2 6s linear infinite;
	animation: animateLetter2 6s linear infinite;
	-webkit-transform: scale(0.3);
	-moz-transform: scale(0.3);
	transform: scale(0.3);

	.mail {
		background-image: url(./images/animated-mail-1.png) ;
		background-repeat: no-repeat;
		background-size: contain;
        background-position: top center;
		height: 131px;
		position: relative;
        top: 40px;
		width: 180px;
		opacity: .6;
	}
}

.x3 {
	-webkit-animation: animateLetter2 9s linear infinite;
	-moz-animation: animateLetter2 9s linear infinite;
	animation: animateLetter2 9s linear infinite;
	-webkit-transform: scale(0.7);
	-moz-transform: scale(0.7);
	transform: scale(0.7);

	.mail {
		background-image: url(./images/animated-mail-2.png);
		background-repeat: no-repeat;
		background-position: top left;
		background-size: contain;
		
		position: relative;
        bottom: 100px;
        height: 51px;
		width: 134px;
		opacity: .5;
	}
}



    .x4 {
        -webkit-animation: animateLetter 7s linear infinite;
        -moz-animation: animateLetter 7s linear infinite;
        animation: animateLetter 7s linear infinite;
        -webkit-transform: scale(0.8);
        -moz-transform: scale(0.8);
        transform: scale(0.8);

        .mail {
            background-image: url(./images/animated-mail-2.png);
            background-repeat: no-repeat;
            background-position: top left;
            background-size: contain;
            height: 51px;
            width: 134px;
            position: relative;
            bottom: 320px;
            opacity: .6;
        }
    }

}